<template>
  <div>
    <!--Search confront-->
    <b-card>
      <b-card-header
        v-if="!loadingConfronts"
      >
        {{ $t('confront.warehouse.list.confrontationControlNumber') }}
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            v-if="!loadingConfronts"
            sm="6"
          >
            <b-form-input
              v-model="searchControlNumber"
              :placeholder="$t('confront.warehouse.list.controlNumber')"
              type="number"
            />
          </b-col>
          <b-col
            v-if="!loadingConfronts"
            sm="6"
          >
            <b-button
              variant="success"
              :disabled="searchControlNumber === ''"
              @click="onSearchClick"
            >
              {{ $t('Lists.Search') }}
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-col>
          <div
            v-if="loadingConfronts"
            class="d-flex align-items-center"
          >
            <b-spinner class="ml-auto" />
            <strong class="ml-1">{{ $t('confront.warehouse.list.loading') }}</strong>
          </div>
        </b-row>
      </b-card-body>
    </b-card>
    <!--Confront list-->
    <b-card>
      <b-card-header>
        <strong>{{ $t('confront.warehouse.list.confrontsAvailable') }}</strong>
      </b-card-header>
      <b-card-body>
        <div class="d-flex bd-highlight mb-1">
          <div class="w-25">
            {{ $t('confront.warehouse.list.control') }}
          </div>
          <div class="w-25">
            {{ $t('AppDigitalRecord.list.custom') }}
          </div>
          <div class="w-25">
            {{ $t('confront.warehouse.list.patent') }}
          </div>
          <div class="w-25" />
        </div>
        <div
          v-for="item in confronts"
          :key="item.relacionDocumentoId"
          class="d-flex bd-highlight mb-1"
        >
          <div
            class="w-25"
          >
            {{ item.control }}
          </div>
          <div
            class="w-25"
          >
            {{ item.aduana }}
          </div>
          <div
            class="w-25"
          >
            {{ item.patente }}
          </div>
          <div
            class="w-25"
          >
            <b-button
              variant="primary"
              :to="{ name: 'apps-confronts-warehouse-check', params: { confront: item } }"
            >
              <feather-icon icon="PlayIcon" />
              {{ $t('confront.warehouse.list.start') }}
            </b-button>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import confrontService from '@/services/confront.service'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
  },
  setup() {
    /* Services */
    const { fetchConfronts } = confrontService()
    /* Data */
    const loadingConfronts = ref(false)
    const searchControlNumber = ref('')
    const confronts = ref([])
    /* Events */
    const onSearchClick = () => {
      loadingConfronts.value = true
      fetchConfronts(searchControlNumber.value)
        .then(data => {
          confronts.value = data
        })
        .finally(() => {
          loadingConfronts.value = false
        })
    }
    /* UI */
    return {
      // Data
      searchControlNumber,
      confronts,
      // Events
      onSearchClick,
      // UI
      loadingConfronts,
    }
  },
}
</script>

<style>

</style>
